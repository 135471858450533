<template>
  <div class="d-flex">
    <v-data-table
      ref="table"
      v-model="tableModel"
      :headers="tableHeaders"
      :items="tableRecords"
      :loading="isLoading"
      style="
        background: transparent;
        display: flex;
        flex-direction: column;
        flex: 1;
      "
      dense
      item-key="id"
      light
      :height="tableHeight"
      :show-select="canSelect"
      fixed-header
      :hide-default-footer="hideFooter"
      :footer-props="footerProps"
      :server-items-length="itemsTotal || undefined"
      :items-per-page="itemsPerPage"
      :sort-by="autoSortColumn"
      :sort-desc="sortDesc"
      @click:row="clickRow"
      @update:options="onOptionsUpdate"
    >
      <template #body.prepend>
        <slot name="body.prepend"></slot>
      </template>
      <template
        v-for="documentColumnName in getDocumentColumnNames"
        #[`item.${documentColumnName}`]="{ item }"
      >
        <td :key="documentColumnName" class="document-column">
          <document-cell
            :item="item"
            :column="documentColumnName"
            :table-id="tableId"
            :project-id="project.id"
            @previewFile="setDocumentPreview"
          />
        </td>
      </template>
      <template
        v-for="booleanColumn in getBooleanColumns"
        #[`item.${booleanColumn}`]="{ item }"
      >
        <td :key="booleanColumn">
          <v-simple-checkbox
            v-if="item[booleanColumn] !== null"
            disabled
            :ripple="false"
            :value="getBooleanValue(item[booleanColumn])"
          />
          <div v-else>-</div>
        </td>
      </template>
      <template
        v-for="linkColumn in getLinkColumns"
        #[`item.${linkColumn}`]="{ item }"
      >
        <td :key="linkColumn">
          <a target="_blank" :href="item[linkColumn]">{{ item[linkColumn] }}</a>
        </td>
      </template>
      <template
        v-for="tmp in tableHeaders.filter((x) => x.hasHeaderSlot)"
        #[`header.${tmp.value}`]="{ item }"
      >
        <slot :name="`header.${tmp.value}`" :header="tmp" />
      </template>
      <template
        v-for="header in tableHeaders.filter((x) => x.hasSlot)"
        #[`item.${header.value}`]="{ item }"
      >
        <slot
          :name="`item.${header.value}`"
          :value="item[header.value]"
          :row-id="item.id"
          :item="item"
          :header="header"
        />
      </template>
      <template
        v-for="column in getRelatedColumn"
        #[`item.${column.name}`]="{ item }"
      >
        <td v-if="column.relation.related_column" :key="column.id">
          {{ item[column.name] }}
        </td>
        <td v-else :key="column.id">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="clickable-relation"
                v-on="on"
                @click="openRelatedObject(column.relation, item[column.name])"
              >
                {{ item[column.name] }}
              </span>
            </template>
            <span>Open related object</span>
          </v-tooltip>
        </td>
      </template>
      <template #top>
        <v-toolbar v-if="hasTop" flat color="transparent">
          <v-toolbar-title v-if="tableTitle.length">
            {{ tableTitle }}
          </v-toolbar-title>
          <v-divider v-if="tableTitle.length" class="ml-4" inset vertical />
          <span class="ma-1"></span>
          <v-tooltip v-if="canRefresh" bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                class="ant-icon ml-2"
                dense
                v-bind="attrs"
                @click="reloadData"
                v-on="on"
                >mdi-refresh</v-icon
              >
            </template>
            <span>Refresh table</span>
          </v-tooltip>
          <slot name="table-buttons" />

          <v-spacer />
          <slot name="table-actions" />
          <v-menu v-if="hasOptions" v-model="optionsMenu" bottom left>
            <template #activator="{ on, attrs }">
              <v-icon class="ant-icon ml-2" dense v-bind="attrs" v-on="on"
                >mdi-dots-vertical</v-icon
              >
            </template>
            <v-list dense>
              <slot name="table-options-menu" />
              <v-list-item v-if="useDefaultExport" @click="exportTableToCSV">
                <v-list-item-icon style="margin-right: 10px">
                  <v-icon dense> mdi-file-download </v-icon>
                </v-list-item-icon>
                <v-list-item-title> Export to CSV </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <slot name="table-row-actions" :item="item" />
        <v-tooltip v-if="canViewRelations && tableRelations.length > 0" bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2 ant-icon"
              v-on="on"
              @click.stop="openRelatedObjectDialog(item)"
            >
              mdi-vector-link
            </v-icon>
          </template>
          <span>View relations</span>
        </v-tooltip>
        <v-tooltip v-if="canEdit" bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2 ant-icon"
              v-on="on"
              @click.stop="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip v-if="canDelete" bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2 ant-icon"
              v-on="on"
              @click.stop="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template #no-data>
        <v-alert type="info" dense class="mt-2 mb-2">
          No data in table
        </v-alert>
      </template>
    </v-data-table>
    <table-object-dialog
      :dialog="tableObjectDialog"
      :relation="tableObjectRelation"
      :record="tableObjectRecordId"
      @closeDialog="closeTableObjectDialog"
    />

    <table-related-records-dialog
      :dialog="relatedObjectsDialog"
      :relations="relatedObjectsRelations"
      :record="relatedObjectsRecordId"
      @closeDialog="closeRelatedObjectsDialog"
    />

    <v-dialog key="table-preview" v-model="previewDialog">
      <div style="height: 80vh" class="iframe-container">
        <iframe
          :src="documentPreview"
          type="application/pdf"
          height="100%"
          width="100%"
        />
        <v-btn
          class="close-iframe"
          small
          fab
          color="primary"
          @click="previewDialog = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import FileHandlerService from '@/services/file-handler';
import { bookOfObjects } from '@/services/bookOf';
import TableObjectDialog from '@/components/Dialogs/TableObjectDialog';
import DocumentCell from '@/components/DynamicDataTable/DocumentCell';
import { mapGetters } from 'vuex';
import moment from 'moment';
import DataHelper from '@/services/data-helper';
import TableRelatedRecordsDialog from '@/components/Dialogs/TableRelatedRecordsDialog.vue';

export default {
  name: 'DynamicDataTable',
  components: { TableRelatedRecordsDialog, TableObjectDialog, DocumentCell },
  props: {
    tableHeaders: {
      type: Array,
      required: true,
    },
    tableRecords: {
      type: Array,
      required: true,
    },
    tableColumns: {
      type: Array,
      default: () => [],
    },
    tableRelations: {
      type: Array,
      default: () => [],
    },
    hasTop: {
      type: Boolean,
      default: true,
    },
    tableTitle: {
      type: String,
      default: '',
      required: false,
    },
    canViewRelations: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    tableId: {
      type: String,
      default: undefined,
    },
    projectId: {
      type: String,
      default: undefined,
    },
    clickableRows: {
      type: Boolean,
      default: false,
    },
    canRefresh: {
      type: Boolean,
      default: false,
    },
    hasOptions: {
      type: Boolean,
      default: false,
    },
    canSelect: {
      type: Boolean,
      default: false,
    },
    tableHeight: {
      type: String,
      default: '100%',
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 0,
    },
    itemsTotal: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 25,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [10, 25, 50, 100, -1],
    },
    autoSortColumn: {
      type: String,
      default: '',
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    useDefaultExport: {
      type: Boolean,
      default: true,
    },
    exportFileName: {
      type: String,
      default: '',
    },
    csvExportFunction: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data: () => {
    return {
      optionsMenu: false,
      tableModel: undefined,
      tableObjectDialog: false,
      tableObjectRelation: {},
      tableObjectRecordId: '',
      documentPreview: undefined,
      previewDialog: false,

      relatedObjectsDialog: false,
      relatedObjectsRelations: [],
      relatedObjectsRecordId: '',
    };
  },
  computed: {
    tablePagination() {
      if (!this.itemsTotal) return {};
      const pageStart = (this.page - 1) * this.itemsPerPage;
      return {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pageCount:
          this.itemsTotal && this.itemsPerPage
            ? Math.ceil(this.itemsTotal / this.itemsPerPage)
            : 1,
        pageStart: pageStart,
        pageStop: pageStart + this.itemsPerPage,
        itemsLength: this.itemsTotal || 0,
      };
    },
    getDocumentColumnNames() {
      return [...this.tableColumns]
        .filter((column) => {
          return column.type === 'document';
        })
        .map((column) => column.name);
    },
    getLinkColumns() {
      return [...this.tableColumns]
        .filter((column) => {
          return column.type === 'link';
        })
        .map((column) => column.name);
    },
    getBooleanColumns() {
      return [...this.tableColumns]
        .filter((column) => {
          return column.type === 'boolean';
        })
        .map((column) => column.name);
    },
    getRelatedColumn() {
      return this.tableColumns.filter((column) => {
        return column.relation;
      });
    },
    footerProps() {
      const props = {
        'items-per-page-options': this.itemsPerPageOptions,
      };
      if (this.itemsTotal) {
        props.pagination = this.tablePagination;
      }
      return props;
    },
    ...mapGetters(['projectTables', 'project']),
  },
  watch: {
    tableModel(value) {
      this.$emit('selectedRecords', value);
    },
    documentPreview(value) {
      this.previewDialog = !!value;
    },
  },
  methods: {
    openRelatedObjectDialog(item) {
      this.relatedObjectsDialog = true;
      this.relatedObjectsRelations = this.tableRelations;
      this.relatedObjectsRecordId = item.id;
    },
    closeRelatedObjectsDialog() {
      this.relatedObjectsDialog = false;
      this.relatedObjectsRelations = [];
      this.relatedObjectsRecordId = '';
    },
    setDocumentPreview(value) {
      this.documentPreview = value;
    },
    getBooleanValue(value) {
      return DataHelper.getBooleanValue(value);
    },
    closeTableObjectDialog() {
      this.tableObjectDialog = false;
      this.tableObjectRelation = Object.assign({}, {});
      this.tableObjectRecordId = '';
    },
    exportTableToCSV() {
      let records = this.csvExportFunction();

      if (!records) {
        records = [...this.tableRecords];
      }

      let name;
      if (this.exportFileName) {
        name = this.exportFileName;
      } else {
        let table = this.projectTables.find(
          (table) => table.id === this.tableId
        );
        if (!table) {
          this.$store.commit('showNotification', {
            content: 'Table not found within project',
            color: 'error',
          });
          return;
        } else {
          name = `${table.name}_${moment().format('YYYY-MM-DD')}`;
        }
      }
      let book = bookOfObjects(
        'records',
        records.map(({ session, ...keepAttrs }) => keepAttrs)
      );
      let csv = book.convert('csv', 'string');
      let parsedCsv = btoa(unescape(encodeURIComponent(csv)));
      FileHandlerService.downloadCSV(name, parsedCsv);
    },
    onPageUpdate(options) {
      console.log('page updated', options);
    },
    onOptionsUpdate(options) {
      this.$emit('onOptionsUpdate', options);
    },
    editItem(item) {
      this.$emit('editItem', item);
    },
    deleteItem(item) {
      this.$emit('deleteItem', item);
    },
    reloadData() {
      this.$emit('reloadData');
    },
    clickRow(row) {
      if (this.clickableRows) {
        this.$emit('clickedRow', row);
      }
    },
    openRelatedObject(relation, id) {
      this.tableObjectRecordId = id;
      this.tableObjectRelation = relation;
      this.tableObjectDialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
.clickable-relation {
  color: var(--v-primary-base);
  transition: 200ms;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.document-column {
  .v-icon {
    opacity: 0;
  }

  &:hover {
    .v-icon {
      opacity: 1;
    }
  }
}

.iframe-container {
  position: relative;

  .close-iframe {
    position: absolute;
    top: 10px;
    left: calc(50% - 12px);
  }
}

.v-data-table {
  overflow-x: auto !important;
}
</style>
